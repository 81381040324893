import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import tickImg from "../images/banner-tick.svg"
import "../components/BannerIntro/BannerIntro.scss"

const ThanyouQuote = (props) => {
    
//var price = props?.location?.state?.price
var price =  parseFloat(props?.location?.state?.price.replace(/,/g, ""));
var stateprice = price * 0.0006
var address = props?.location?.state?.address
if(stateprice > 180 ) {
    stateprice = stateprice
} else {
    stateprice = '180';
}
return (
<React.Fragment>
<Header />
<div className="section-p banner-into-wrapper">
    <Container className="banner-container">
        <img src={tickImg} alt="banner-tick" className="banner-tick" />
    <div className="content">
        <h4 className="heading">YOUR QUOTE</h4>
        {/* <h1 className="title">The quote for guaranteeing your property sale is £{stateprice.toLocaleString()}</h1> */}
        <div className="description"><p>Thank you for requesting a quote for ClozeSure.  This will be sent to the email address you provided, along with further instructions.  You should receive this within the next 10 minutes.  If you have not received it in this time, please check your junk or spam folder.</p></div>
        <div className="cta-section">
            <Link to="/contact-clozesure/" className="btn btn-pink btn-primary">Contact us</Link>
            <Link to="/" className="btn btn-white btn-secondary">Maybe later</Link>
        </div>        
    </div>
    </Container>
</div>
    <Footer />
</React.Fragment>
)
}
export default ThanyouQuote;

